import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import './index.css';
import i18n from './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Login from "./containers/Auth/Login";
import Logout from "./containers/Auth/Logout";

const app = (
    <I18nextProvider i18n={ i18n }>
        <BrowserRouter>
            <div>
                <Route path="/" render={() => <App />} />
                <Route path="/login" render={() => <Login />} />
                <Route path="/logout" render={() => <Logout />} />
            </div>
        </BrowserRouter>
    </I18nextProvider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
