import Fingerprint2 from 'fingerprintjs2'


class BrowserUUID {

    static storeBrowserUUID = () => {
        if (window.requestIdleCallback) {
            requestIdleCallback(function () {

                Fingerprint2.get((result, component) => {
                    return BrowserUUID.handleFingerPrintResult(result)
                });
                
            })
        } else {
            setTimeout(function () {
                Fingerprint2.get((result, component) => {
                    return BrowserUUID.handleFingerPrintResult(result)
                });  
            }, 500)
        }
    }

    static handleFingerPrintResult = (result) => {
        console.log(result)
        
        var string = ""
        for(var i = 0 ; i<result.length; i++){
            string += result[i].value
        }
        const fingerprint = Fingerprint2.x64hash128(string, 10)
        BrowserUUID._storeBrowserUUID(fingerprint)
    }
    
    static _storeBrowserUUID = (uuid) => {
        if (BrowserUUID.isLocalStorageAvailable()){
            localStorage.setItem("browser_uuid", uuid)
        }else {
            console.log("Error: Browser doesn't support local storage");
        }
    }
    
    static getBrowserUUID = () => {
        if (BrowserUUID.isLocalStorageAvailable()){
            return localStorage.getItem("browser_uuid")
        }
        return null
    }

    static isLocalStorageAvailable = () => {
        return (localStorage != null)
    }
}

export default BrowserUUID

