import React, { Component } from 'react';

import classes from './PaymentForm.module.css';
import fpLogo from '../../assets/img/logo.png';


import CreditCardInput from './CreditCard/CreditCardInput'
import IntlTelInput from 'react-intl-tel-input';




// VISA         : XXXX - XXXX - XXXX - XXXX
// MASTERCARD   : XXXX - XXXX - XXXX - XXXX
// AMERICAN EXP : XXXX - XXXX - XXXX - XXX

class PaymentForm extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            creditCardNumber: '',
            expiryDateMonth: '',
            expiryDateYear: '',
            titularyName: '',
            cvc: '',

            invalidCreditCard: false,
            invalidCVC: false,
            invalidCardName: false,
            invalidMailAddress: false,
            invalidPhoneNumber: false,
        };
        
        this.handleExpiryMonthChange = this.handleExpiryMonthChange.bind(this);
        this.handleExpiryYearChange = this.handleExpiryYearChange.bind(this);
        this.handleCVCChange = this.handleCVCChange.bind(this);
        this.handleTitularyCardNameChange = this.handleTitularyCardNameChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleExpiryMonthChange(event) {
        console.log("expiration month changed : "+ event.target.value)
        this.setState({expiryDateMonth: event.target.value});
    }
    
    handleExpiryYearChange(event) {
        console.log("expiration year changed : "+ event.target.value)
        this.setState({expiryDateYear: event.target.value});
    }
    
    handleTitularyCardNameChange(event) {
        this.setState({titularyName: event.target.value});
    }
    
    handleCVCChange(event) {
        if(event.target.value.length > 3){
            event.target.value = this.state.cvc
            return
        }
        this.setState({cvc: event.target.value});
    }

    onCreditCardNumberChanged = (cardType, cardNumber) => {
        this.setState({cardType: cardType, creditCardNumber: cardNumber})
    }
    

    handleSubmit(event) {
        event.preventDefault();
        
        if(!this.isValidForm()){
            alert("Invalid form, set error to htlml fields")
            return
        }
        alert("Accepted payment")
        this.props.onPurchaseCompleted()
    }

    isValidForm = () => {
        return this.isValidCard() && this.isValidCVC() && this.isValidExpiryDate() && this.isValidTitularyCardName()
    }

    // Credit card validation infos : https://www.w3resource.com/javascript/form/credit-card-validation.php
    isValidCard = () => {
        const creditCardNumber = this.state.creditCardNumber
        var separatorCount = (creditCardNumber.match(/-/g) || []).length;
        // It should be 4 parts (separates with 3 "-")
        if(separatorCount !== 3){
            return false
        }

        if(isNaN(creditCardNumber.replace(new RegExp("-", "g"),""))){
            return false
        }

        switch(this.state.cardType){
            case "mastercard": return (creditCardNumber.replace(new RegExp("-", "g"),"").length === 16)
            case "visa": return (creditCardNumber.replace(new RegExp("-", "g"),"").length === 13) || (creditCardNumber.replace(new RegExp("-", "g"),"").length === 16)
            case "americanexpress": return (creditCardNumber.replace(new RegExp("-", "g"),"").length === 15)
            default: break
        }
        return false
    }

    isValidExpiryDate = () => {

        console.log(this.state)

        const month = this.state.expiryDateMonth
        const year = this.state.expiryDateYear

        const monthValue = parseInt(month)
        const yearValue = parseInt(year)
        if(isNaN(monthValue) || isNaN(yearValue)){
            return false
        }

        // Check if month is correct
        if(monthValue < 1 || monthValue >12){
            return false
        }
        const now = new Date()
        const currentFullYear = now.getFullYear()
        const currentMonth = now.getMonth() + 1 // + 1 because getMonth (returns 0-11 value)

        if(yearValue < currentFullYear || (yearValue === currentFullYear &&  monthValue < currentMonth)){
            return false
        }
        
        return true
    }

    isValidCVC = () => {
        return (!isNaN(this.state.cvc) && this.state.cvc.length === 3)
        
    }

    isValidTitularyCardName = () => {
        return true
    }
    
    render() {
        const now = new Date(Date.now())
        
        const currentFullYear = now.getFullYear()
        const currentMonth = now.getMonth()
        var yearsOptions = [currentFullYear, currentFullYear + 1, currentFullYear + 2, currentFullYear + 3, currentFullYear + 4, currentFullYear + 5, currentFullYear + 6]
        
        var months = {"1": "January", "2": "February", "3": "March", "4": "April", "5": "May", "6": "June", "7": "July", "8": "August", "9": "September", "10": "October", "11": "November", "12": "December"}
        if(this.state.expiryDateYear === currentFullYear){
            
            months = Object.keys(months)
                .filter(key => key > currentMonth)
                .map((key, _) => {
                    return <option value={key} key={key} selected={key===this.state.expiryDateMonth ? "selected" : null}>{months[key]}</option>
                
                })
        }else{
            months = Object.keys(months)
            .map((key, _) => {
                return <option value={key} key={key} selected={key===this.state.expiryDateMonth ? "selected" : null}>{months[key]}</option>
            })
        }
        
        //TODO: Create credit card input with type detector (visa, mastercard, american express)
        return (
            <form className={classes.PaymentForm} onSubmit={this.handleSubmit}>
                <div className={classes.PaymentContainer}>
                    <img src={fpLogo} className={classes.Logo} alt="fastpoint logo"/>
                    <CreditCardInput onCreditCardNumberChanged={this.onCreditCardNumberChanged} required/>
                    <div className={classes.PaymentFormGroupLeft}>
                        <label className={classes.PaymentInputLabel}>Expiration</label>
                        
                        <div style={{display:"flex"}}>
                        <select style={{width: "50%", marginRight:"5%"}} onChange={this.handleExpiryMonthChange} className={classes.PaymentInput}>
                            <option value="--">--</option>
                            {months}
                        </select> 
                        
                        <select style={{width: "45%"}} onChange={this.handleExpiryYearChange} className={classes.PaymentInput}>
                            <option value="----">----</option>
                            {
                                yearsOptions.map((year, i) => {     
                                    return (<option key={year} value={year}>{year}</option>)
                                })
                            }
                        </select> 
                        </div>
                    </div>
                    <div className={classes.PaymentFormGroupRight}>
                        <label className={classes.PaymentInputLabel}>CVC / CVV</label>
                        <input onChange={this.handleCVCChange} className={classes.PaymentInput} style={{width:'100%'}} type="text" minLength="3" maxLength="3" placeholder="XXX" required />
                    </div>
                    <div className={classes.PaymentFormGroup}>
                        <label className={classes.PaymentInputLabel}>Name on the card</label>
                        <input onChange={this.handleTitularyCardNameChange} className={classes.PaymentInput} type="text" placeholder="John DOE" required/>
                    </div>


                    <div className={classes.PaymentFormGroupLeft}>
                        <label className={classes.PaymentInputLabel}>Mail address</label>
                        <input onChange={this.handleMailAddressChange} className={classes.PaymentInput} type="email" placeholder="john.doe@gmail.com" required/>
                    </div>
                    <div className={classes.PaymentFormGroupRight}>
                        <label className={classes.PaymentInputLabel}>Phone number</label>
                        <IntlTelInput autoPlaceholder className={classes.PaymentInput} inputClassName={classes.PaymentTelInput}  /*onChange={this.handlePhoneChange} className={classes.PaymentInput} style={{width:'100%'}} placeholder="01 23 45 67 89"*/ />
                    </div>



                    <input className={classes.PaymentValidateButton} type="submit" value="Pay now (2.90€)" />  
                    <div className={classes.PaymentSeparatorGroup}>
                        <div className={classes.PaymentLeftSeparator}></div>
                            <label className={classes.PaymentSeparatorLabel}>OR</label>
                            <div className={classes.PaymentRightSeparator}></div>
                        </div>
                    {/* <div>
                        <input className={classes.PaymentPaypalButton} type="button" value=""/>
                        <label className={classes.PaymentPaypalLabel}>Checkout with</label>
                        <img className={classes.PaymentPaypalLogo} src={paypalLogo} alt="paypal"/>
                    </div> */}
                    <button className={classes.PaymentBackButton} onClick={this.props.onPurchaseCanceled}>← Back to movie details</button>
                </div>
            </form>
            )
        }
    };
    
    export default PaymentForm;