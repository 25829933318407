/**
 * Return boolean to indicate if browser is a chromium based browser
 * @param {*} props 
 */
export const isChromiumBasedBrowser = () => {
    return !!window.chrome && !/Edge/.test(navigator.userAgent)
};

export const chromiumVersion = () => {
    if(isChromiumBasedBrowser){
        let userAgent = navigator.userAgent
        let chromePrefix = "Chrome/"
        let chromeIndex = userAgent.indexOf(chromePrefix)

        let secondPart = userAgent.substr(chromeIndex + chromePrefix.length)
        let majorVersionDotIndex = secondPart.indexOf(".")

        return userAgent.substr(chromeIndex + chromePrefix.length, majorVersionDotIndex)
    }else{
        return -1
    }
};