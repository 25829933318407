import * as global from '../../../const'
import BrowserUUID from '../../Utilities/Browser/BrowserUUID/BrowserUUID'


const generateJsonBase64FromMedia = (media, dummyInformations = false) => {
    let fpContent = {
        id: media.id,
        ckey: media.clientKey,
        cp: global.CONTENT_PROVIDER,
        deviceId: BrowserUUID.getBrowserUUID()
    };
    if(dummyInformations){
        fpContent["rcode"] = "dummy"
        fpContent["dToken"] = "dummy"
    }
    return Buffer.from(JSON.stringify(fpContent)).toString("base64")
};

export default generateJsonBase64FromMedia;

