import React from 'react'
import {Player} from "@fastpoint/react-lib";
import classes from "./Player.css";
import arrow from '../../assets/img/arrow-left.svg'
import { Trans } from "react-i18next";

const videoPlayer = (props) => {
    return (
        <>
            <div className={classes.BackButton} onClick={props.modalClosed}>
                <img src={arrow} alt="Back"/>
                <p className={classes.BackButtonLabel}><Trans>BackToDetails</Trans></p>
            </div>

            <Player {... props} />
        </>
    )
}

export default videoPlayer
