import React, { Component } from 'react';

import paymentClasses from '../PaymentForm.module.css'

import visaLogo from '../../../assets/img/visa.svg'
import mastercardLogo from '../../../assets/img/mastercard.svg'
import americanExpressLogo from '../../../assets/img/americanexpress.svg'




class CreditCardInput extends Component {
    
    
    constructor(props){
        super(props);
        this.state = {
            cardType: '',
            creditCardNumber: ''
        }
        this.handleCreditCardChange = this.handleCreditCardChange.bind(this);
    }

    handleCreditCardChange(event) {
        // Keep a copy of old value
        const oldInput = this.state.creditCardNumber

        // If new input is shortest than before, it's a deletion we return
        if(event.target.value.length < oldInput.length){
            this.setState({creditCardNumber: event.target.value});

            const cardType = this.getCardType(event.target.value)
            this.props.onCreditCardNumberChanged(cardType, event.target.value)
            return
        }
        
        const input = event.target.value
        const number = input.replace(new RegExp('-', 'g'),"")
        const numberLength = number.length
        
        if(numberLength > 16 || isNaN(number)){
            event.target.value = this.state.creditCardNumber
            return
        }

        let newValue = ''
        for (var i = 0; i < number.length; i++) {
            newValue += number.charAt(i)
            const newNumberValue = newValue.replace(new RegExp('-', 'g'),"")
            if(newNumberValue.length % 4 === 0 && newNumberValue.length <= 15){
                newValue += '-'
            }
        }
        event.target.value = newValue
        
        this.setState({creditCardNumber: event.target.value});
        const cardType = this.getCardType(event.target.value)
        this.props.onCreditCardNumberChanged(cardType, event.target.value)
        
    }
    
    getCardType = (cardNumber) => {
        const creditCardNumberPrefix = parseInt(cardNumber.substr(0, 2))
        if(cardNumber.substr(0, 1) === "4"){
            return "visa"
        }else if(creditCardNumberPrefix >= 50 && creditCardNumberPrefix <= 55){
            return "mastercard"
        }else if(cardNumber.substr(0, 2) === "34" || cardNumber.substr(0, 2) === "37"){
            return "americanexpress"
        }else {
            return null
        }
    }

    getCardLogo = () => {
        const cardType = this.getCardType(this.state.creditCardNumber)
        switch(cardType){
            case "mastercard" : 
                return mastercardLogo
            case "visa" : 
                return visaLogo
            case "americanexpress" : 
                return americanExpressLogo
            default:
                return null
        }
    }
    
    render() {
        const cardLogo = this.getCardLogo()
        const cardImg = cardLogo == null ? null : <img className={paymentClasses.PaymentInputCreditCardLogo} src={cardLogo} alt="credit card icon" />
        return (
            <div className={paymentClasses.PaymentFormGroup}>
                <label className={paymentClasses.PaymentInputLabel}>Credit card number</label>
                {cardImg}
                <input onChange={this.handleCreditCardChange} className={paymentClasses.PaymentInput} type="text" placeholder="xxxx-xxxx-xxxx-xxxx" required={this.props.required}/>
            </div>
        )
    }
}

export default CreditCardInput;