import React from 'react';
import classes from './Toolbar.css';
import Logo from '../../Logo/Logo';

import fpLogo from '../../../assets/img/logo.png';

import { NavLink } from 'react-router-dom'


const toolbar = (props) => {
    return (
        <header className={classes.Toolbar}>
            <div className={classes.LogoLeft}>
                <Logo logo={fpLogo} />
            </div>
            <div className={classes.LogoRight} >
            <NavLink style={{ textDecoration: 'none', color: 'white', fontSize: "20px"}} to="/logout">
                LOGOUT
            </NavLink>
            </div>
        </header>
    );
};

export default toolbar;
