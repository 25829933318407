import { chromiumVersion, isChromiumBasedBrowser } from "./ChromiumHelper"
import { isIOS, isSafari, isAndroid, isMobile, isFirefox, isEdge, isOpera, browserVersion } from "react-device-detect";

export const isBrowserCompatible = () => {
        
    // SafariPlayer with Fairplay
    if (isSafari) { return true }
    
    
    // ShakaPlayer with CMAF works on Chromium 68+ 
    // Doesn't works only on iOS devices
    if (isChromiumBasedBrowser && !isIOS && chromiumVersion() >= 68) { return true }
    
    // Firefox works with CMAF on desktop versions only
    if (isFirefox && !isMobile) { return true } 
    //TODO: Check minimal browser version to support CMAF
    
    // Edge on Android is based on Chromium
    if (isEdge && isAndroid) { return true }
    
    // ShakaPlayer with CMAF works on Opera Chromium versions (>= 15.0.0) (2013 version)
    if (isOpera && !isMobile){
        // Extract major version (ex : 60.0.0 => 60)
        const dotIndex = browserVersion.indexOf(".")
        if(dotIndex !== -1){
            const majorBrowserVersion = browserVersion.substring(0, dotIndex)
            // Opera moved on Chromium rendering engine since 15.0.0 version
            if(majorBrowserVersion >= 15){ return true }
        }
    }
    
    return false
}