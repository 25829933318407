import React from "react";
import { Trans } from "react-i18next";
import { withRouter } from "react-router-dom";

import classes from "./NavigationItems.css";
import NavigationItem from "./NavigationItem/NavigationItem";

/* 
    "options": ["", "movie", "serie", "book", "newspaper", "music"]
*/
const navigationItems = props => {
    let menu = [];
    menu = props.mediaTypes.map(el => {
        const labelType = props.mediaLabel[el]
            ? props.mediaLabel[el]
            : el.charAt(0).toUpperCase() + el.slice(1);
        return (
            <NavigationItem link={"/" + el} key={el}>
                <Trans>{labelType}</Trans>
            </NavigationItem>
        );
    });
    return <ul className={classes.NavigationItems}>{menu}</ul>;
};

export default withRouter(navigationItems);
