import React, { Component } from 'react';

import AuthMethodsHelper from '../../helpers/AuthHelperMethods'
import Spinner from '../../components/UI/Spinner/Spinner'


class Logout extends Component {

    state = {
        isSignoutFinished: false
    }

    async componentWillMount(){
        await new AuthMethodsHelper().logout()
    }

    render() {
        if(this.state.isSignoutFinished){
            // User will be redirected
            return null
        }else{
            return (
                <>
                    <Spinner />
                    <p>Logout in progress</p>
                </>
            )
        }
    }
}

export default Logout;
