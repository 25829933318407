import React, { Component } from 'react';
import classes from './PlayerModal.css';
import Backdrop from '../../../components/UI/Backdrop/Backdrop'

class PlayerModal extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
    }

    render () {
        return (
            <React.Fragment>
                <Backdrop show={this.props.show} clicked={this.props.modalClosed}/>
                <div className={classes.PlayerModal}
                    style={{
                        transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
                        opacity: this.props.show ? '1' : '0',
                    }}>
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}

export default PlayerModal;