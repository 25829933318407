import decode from "jwt-decode";
import global from '../const'

export default class AuthHelperMethods {

    // Initializing important variables
    constructor() {
        this.domain = "https://api.fast-point.net/prod"
    }

    login = async(username, password) => {
        // Get a token from api server using the fetch api
        const res = await this.fetch(this.domain+'/login', {
            method: "POST",
            body: JSON.stringify({
                username: username,
                password: password
            })
        })

        if(res.success === true){
            await this.setToken(res.response.token); // Setting the token in localStorage
        }
        return res

    };

    loggedIn = () => {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken(); // Getting token from localstorage
        return !!token && !this.isTokenExpired(token); // handwaiving here
    };

    isTokenExpired = token => {
        try {
            const decoded = decode(token);
            return decoded.exp < new Date().getTime() / 1000
        } catch (err) {
            console.log("expired check failed! Line 41: AuthService.js" + err);
            return false;
        }
    };

    willTokenExpire = () => {
        // If token will expire in less than 10 minutes, we refresh
        const maxMinutesBeforeRefresh = 10
        if(!this.loggedIn){
            return true
        }
        try {
            const decoded = decode(this.getToken());
            const shouldRefreshAt = (decoded.exp) - (60*maxMinutesBeforeRefresh)
            return shouldRefreshAt < (new Date().getTime() / 1000)
        } catch (err) {
            console.log("expired check failed! Line 59: AuthService.js");
            return false;
        }
    }

    refreshToken = async() => {
        const res = await this.fetch(this.domain+'/refreshtoken', {
            method: "POST",
            body: JSON.stringify({
                'fp-token': this.getToken(),
            })
        })

        if(res.success === true){
            await this.setToken(res.response.token); // Setting the token in localStorage
        }
        return res
    }

    setToken = async(idToken) => {
        // Saves user token to localStorage
        await localStorage.setItem("id_token", idToken);
    };

    getToken = () => {
        // Retrieves the user token from localStorage
        //alert("get id_token :" + localStorage.getItem("id_token"))
        return localStorage.getItem("id_token");
    };

    logout = async() => {
        // Clear user token and profile data from localStorage
        await localStorage.removeItem("id_token");
    };

    getConfirm = () => {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    };

    fetch = async (url, options) => {
        // performs api calls sending the required authentication headers
        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key":"EzcPqsGOIg2LjiVV4nFfV4RY9rmZVYMe9UUYhzq4",
        };

        if(this.loggedIn()){
            headers["fp-token"] = this.getToken()
        }
        console.log(headers)

        const response = await fetch(url, {
            headers,
            ...options
        })
        // Verify if status is OK, if not, throw error
        if(!this._checkStatus(response)){
            return { success: false, error: "Invalid credentials"}
        }

        const jsonResponse = await response.json()
        console.log(jsonResponse)
        return { success: true, response: jsonResponse}
    };

    _checkStatus = (response) => {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {
            // Success status lies between 200 to 300
            return true
        } else{
            return false
        }
    };
}
