import React, { Component } from 'react';
import classes from './Login.module.css'
import AuthHelperMethods from '../../helpers/AuthHelperMethods'

import Spinner from '../../components/UI/Spinner/Spinner'
import { Redirect } from 'react-router-dom'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

class Login extends Component {

    state = {
        username: null,
        password: null,
        error: null,

        isJwtTokenLoaded: false,
        requestInProgress: false
    }

    componentDidMount(){
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit = async(event) => {
        event.preventDefault()
        this.setState({requestInProgress: true}, async() => {
            const auth = new AuthHelperMethods();

            const loginResult = await auth.login(this.state.username, this.state.password)
            this.setState({requestInProgress: false}, async() => {
                if(!loginResult.success){
                    this.setState({ error : loginResult.error })
                }else {
                    this.setState({ isJwtTokenLoaded : true })
                }
            })
        })
    }

    handleUsernameChanged = (event) => {
        this.setState({username: event.target.value})
    }

    handlePasswordChanged = (event) => {
        this.setState({password: event.target.value})
    }


    render() {
        if(this.state.isJwtTokenLoaded){
            //window.location.reload();
            return <Redirect to="/"/>
        }

        const validationSection = this.state.requestInProgress ?
            (<div className={classes.LoginSpinnerContainer}>
                <Loader
                    type="ThreeDots"
                    color="#4185F4"
                    height={100}
                    width={100}

                />
            </div>)
            :
            <button>LOG IN</button>

        return (
            <div className={classes.LoginContainer}>
                <h1>FASTPOINT LOGIN</h1>
                <form onSubmit={this.handleSubmit} className={classes.LoginForm}>
                    <label>EMAIL</label>
                    <input type="email" onChange={this.handleUsernameChanged} required/>

                    <label>PASSWORD</label>
                    <input type="password" onChange={this.handlePasswordChanged} required/>

                    {this.state.error != null ? <p style={{color:"red"}}>{this.state.error}</p> : null }

                    {validationSection}
                </form>
            </div>
        );
    }
}

export default Login;
