import React from 'react';
import classes from './Logo.css';

const logo = (props) => {
    return (
        <div className={classes.Logo} style={{height: props.height}}>
            <img src={props.logo} alt="myBurger"/>
        </div>
    );
};

export default logo;