import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import axios from "axios";

import withAuth from './hoc/withAuth/withAuth'
import withErrorHandler from "./hoc/withErrorHandler/withErrorHandler";
import AuthHelperMethods from './helpers/AuthHelperMethods'

import * as global from "./const";
import {isAndroid, isIOS, isMobile, osVersion} from "react-device-detect";
import { isBrowserCompatible } from "./components/Utilities/Browser/BrowserInformations/BrowserHelper"

import Layout from "./hoc/Layout/Layout";
import Medias from "./containers/Medias/Medias";
import Spinner from "./components/UI/Spinner/Spinner";

import { loadFonts } from './helpers/FontAwesomeHelper'
import Footer from "./components/Navigation/Footer/Footer";

import { Core, Status, CatalogFilter } from '@fastpoint/react-lib'

class App extends Component {

    core = Core.getInstance();

    state = {
        isLoaded: false,
        catalog: {},
        contentProvider: null
    };

    constructor(props){
        super(props)
        global.setXAPIKEY(global.licensePlatform.PROD);
        loadFonts()
    }

    async componentDidMount() {
        console.info(global.BUILD_VERSION)
        console.info("OS Version : " + osVersion)

        const thumbnailCookiesResponse = await this.fetchThumbnailCookies();
        const responseData = thumbnailCookiesResponse.data
        for(const field in responseData){
            console.log(field + " = " + responseData[field])
            document.cookie = field + '=' + responseData[field] +';path=/thumbnails/;domain=fast-point.net'
        }



        const mediaspotInfoResponse = await this.core.getMediaspotInfo({"fp-token":new AuthHelperMethods().getToken(), "x-api-key":global.XAPIKEY}, global.BASE_API_URL + "/" + global.PLATFORM + "/mediaspotinfo")
        if(mediaspotInfoResponse.status === Status.ERROR){
            console.log(mediaspotInfoResponse.error)
            return
        }

        this.core.initWithMediaspotInfo(mediaspotInfoResponse.data)

        const catalogResponse = await this.core.getCatalog({"fp-token":new AuthHelperMethods().getToken(), "x-api-key":global.XAPIKEY}, global.BASE_API_URL + "/" + global.PLATFORM + "/index-v4.json",global.BASE_URL)
        if(catalogResponse.status === Status.ERROR){
            console.log(catalogResponse.error)
            return
        }

        this.setState({
            isLoaded: true,
            catalog: this.updateCatalogWithCencIfRequired(catalogResponse.data),
            contentProvider: mediaspotInfoResponse.data.clients[0]
        })
    }

    /**
     * // If current device is a mobile on Android, we play "cenc_filename" file.
     // CMAF only works with Chrome 74+ (not enough distributed)
     * @param catalog: List of MediaItem objects
     * @return {*} List of MediaItems objects with updates "src" for each
     */
    updateCatalogWithCencIfRequired = (catalog) => {
        for(let i = 0 ; i < catalog.length ; i++) {
            //catalog[i].src = catalog[i].src.replace(".mpd", ".m3u8");
            // If media is not encrypted, no need to replace anything else
            if(!catalog[i].isEncrypted){ continue }

            if(isMobile && isAndroid && parseFloat(osVersion) < 7.1){
                const filename = catalog[i].src.replace(/^.*[\\/]/, '');
                catalog[i].src = catalog[i].src.replace(filename, "cenc_"+filename);
            }
        }
        return catalog
    };



    fetchThumbnailCookies = async() => {
        const response = await axios.get(global.BASE_API_URL + "/" + global.PLATFORM + "/getthumbnailcookies", {
            headers: {
                "x-api-key": global.XAPIKEY,
                "fp-token": new AuthHelperMethods().getToken()
            }
        })
        console.log(new AuthHelperMethods().getToken())
        if(response === undefined || response.data === null){
            console.error("Invalid response from /getthumbnailcookies")
        }
        return response
    }

    render() {
        if (global.ENABLE_UNSUPPORTED_BROWSER || isBrowserCompatible()){
            let myApp = <Spinner />;
            if (this.state.isLoaded) {
                myApp = (
                    <Layout
                        mediaTypes={CatalogFilter.getMediaTypes(this.state.catalog)}
                        mediaLabel={CatalogFilter.getMediaTypeLabels(this.state.catalog)}
                    >
                        <Medias {...this.props} catalog={this.state.catalog} contentProvider={this.state.contentProvider} />
                        <Footer version={global.BUILD_VERSION} />
                    </Layout>
                );
            }
            if (this.state.error) {
                myApp = null;
            }

            return (
                myApp
            )

        }
        else if (!global.ENABLE_UNSUPPORTED_BROWSER && !this.isBrowserCompatible()){
            const recommandedBrowser = isIOS ? "Safari" : "Google Chrome"
            return (
                <>
                    <p>Your browser is not compatible, we recommand you to use {recommandedBrowser}</p>
                    <p>
                        Download Chrome <a href={global.OFFLINE_CHROME_INSTALER_URL}>here</a>
                    </p>
                </>
            )

        }

    }
}
export default withAuth(withErrorHandler(withNamespaces("translations")(App), axios));
