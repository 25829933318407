import React, { Component } from "react";
import AuthHelperMethods from "../../helpers/AuthHelperMethods";
import { Redirect } from 'react-router-dom'

import Login from '../../containers/Auth/Login'

/* A higher order component is frequently written as a function that returns a class. */
export default function withAuth(AuthComponent) {

    const Auth = new AuthHelperMethods();

    return class AuthWrapped extends Component {
        state = {
            confirm: null,
            loaded: false,
            refreshTokenFunctionId: null
        };

        /* In the componentDidMount, we would want to do a couple of important tasks in order to verify the current users authentication status
        prior to granting them enterance into the app. */
        componentDidMount() {
            if (!Auth.loggedIn()) {
                this.setState({ loaded: true })
            } else {
                /* Try to get confirmation message from the Auth helper. */
                try {
                    const confirm = Auth.getConfirm();
                    //Display jwt-token
                    console.log("confirmation is:", confirm);
                    this.setState({
                        confirm: confirm,
                        loaded: true
                    });

                    const intervalId = setInterval(() => this.refreshTokenIfNeeded(), 20000)
                    this.setState({ refreshTokenFunctionId : intervalId })
                } catch (err) {
                    /* Oh snap! Looks like there's an error so we'll print it out and log the user out for security reasons. */
                    console.log(err);
                    Auth.logout();
                    this.setState({ loaded: true })
                }
            }
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if(prevState.confirm !== Auth.getToken() && !Auth.isTokenExpired(Auth.getToken())){
                this.setState({confirm: Auth.getToken()})
            }
        }

        async refreshTokenIfNeeded(){
            if(Auth.willTokenExpire()){
                console.log('refresh token')
                const refreshTokenResult = await Auth.refreshToken()
                console.log(refreshTokenResult)
            }
        }

        stopRefreshToken(){
            if(this.state.refreshTokenFunctionId){
                clearInterval(this.state.refreshTokenFunctionId)
            }
        }

        render() {
            if (this.state.loaded == true) {
                if (this.state.confirm != null) {
                    return (
                    /* component that is currently being wrapper(App.js) */
                    <AuthComponent
                        history={this.props.history}
                        confirm={this.state.confirm}
                    />
                    );
                } else {
                    return <Redirect to="/login" />
                }
            } else {
                return null;
            }
        }
    };
}
