import React, {Component} from 'react';
import classes from './Layout.css';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';

import NavigationItems from '../../components/Navigation/NavigationItems/NavigationItems';

class Layout extends Component {

    render = (props) => {
        return (
            <>
                <Toolbar {...this.props}/>
                <NavigationItems {...this.props}/>
                <main className={classes.Content}>      
                    {this.props.children}
                </main>
            </>
            )
        }
    }
    
    export default Layout;